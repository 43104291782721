<template>
  <v-container class="container-form">
    <BackArrow class="mt-8" />
    <v-subheader class="title mt-n4 mb-4 pa-0 black--text">
      {{ id ? "Editar Operador" : "Cadastrar Operador" }}
    </v-subheader>
    <v-form
      ref="form"
      v-model="valid"
      lazy-validation
      autocomplete="off"
      @submit.prevent
    >
      <v-card class="mb-5">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Principais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row justify="center" align="center">
            <v-col cols="12" sm="12" justify="center">
              <v-container class="subContainer">
                <div class="userAvatar" @click="$refs.fileInput.click()">
                  <v-avatar size="120">
                    <img
                      :src="
                        this.dados.foto
                          ? `${urlImages}uploads/` + this.dados.foto
                          : require('@/assets/no-avatar.png')
                      "
                      :alt="this.$store.getters.getOperadorData.login"
                    />
                  </v-avatar>
                  <input
                    type="file"
                    label="Foto:"
                    accept="image/jpeg, image/png"
                    @change="uploadImage"
                    ref="fileInput"
                    style="display: none"
                  />
                </div>
              </v-container>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12">
              <span class="primary--text">Nome Completo *</span>
              <v-text-field
                v-model="dados.nome_completo"
                :rules="fieldRules"
                required
                placeholder="Informe o nome completo"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Login *</span>
              <v-text-field
                v-model="dados.login"
                :rules="fieldRules"
                required
                placeholder="Informe o login"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Senha {{ !id ? "*" : "" }}</span>
              <v-text-field
                v-model="dados.senha"
                :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                :rules="!id ? fieldRules : []"
                :type="show ? 'text' : 'password'"
                name="input-10-1"
                @click:append="show = !show"
                placeholder="Informe a senha"
                class="mt-n1"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card class="mb-15">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>Informações Pessoais</span>
          <span>Campos Obrigatórios *</span>
        </v-card-title>

        <v-card-text class="pt-7 px-7">
          <v-row>
            <v-col cols="12" sm="6">
              <span class="primary--text">E-mail *</span>
              <v-text-field
                v-model="dados.email"
                :rules="emailRules"
                required
                placeholder="Informe o email"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6">
              <span class="primary--text">Telefone *</span>
              <v-text-field
                v-model="dados.telefone"
                :rules="fieldRules"
                v-mask-phone.br
                required
                placeholder="Informe o telefone"
                class="mt-n1"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" v-if="grupoUser == 2">
              <span class="primary--text">Agência *</span>
              <v-autocomplete
                v-model="dados.id_agencia"
                :rules="fieldRules"
                :items="agencias"
                item-value="id"
                item-text="nome"
                required
                placeholder="Informe a agência"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" v-if="grupoUser == 2">
              <span class="primary--text">Grupo de Acesso *</span>
              <v-autocomplete
                :items="grupos"
                item-text="nome"
                item-value="id"
                v-model="dados.id_grupo"
                placeholder="Informe o grupo de acesso"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" v-if="grupoUser == 2">
              <span class="primary--text">Level *</span>
              <v-autocomplete
                :items="levels"
                item-text="desc"
                item-value="id"
                v-model="dados.level"
                placeholder="Informe level de usuário"
                class="mt-1 custom-solo-selection"
                solo
              ></v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>

      <v-footer color="primary" class="rounded-t footer-form" absolute>
        <div class="flex-grow-1"></div>
        <v-btn depressed dark text :disabled="!valid" @click="validate"
          >Confirmar</v-btn
        >
      </v-footer>
    </v-form>

    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
import BackArrow from "../../../components/BackArrow";
import { apiUrlByEnviroment } from "../../../utils";
export default {
  components: {
    BackArrow,
  },
  props: ["id"],
  data() {
    return {
      urlImages: "",
      show: false,
      loading: false,
      message: {
        snacshow: false,
        text: "",
        type: "",
        mode: "",
        timeout: 2000,
      },
      dados: {
        nome_completo: "",
        login: "",
        senha: "",
        email: "",
        telefone: "",
        id_agencia: null,
        id_grupo: null,
        level: null,
        foto: "",
      },
      agencias: [],
      grupos: [],
      levels: [
        { id: 1, desc: "SUPERADMIN" },
        { id: 5, desc: "AGENTES" },
        { id: 3, desc: "OUTROS" },
      ],
      valid: true,
      fieldRules: [(v) => !!v || "Este campo é requerido"],
      emailRules: [
        (v) => !!v || "E-mail é requerido",
        (v) => /.+@.+\..+/.test(v) || "Digite um e-mail válido!",
      ],
      grupoUser: null,
    };
  },
  created() {
    this.urlImages = apiUrlByEnviroment[process.env.NODE_ENV.trim()];
    this.grupoUser = this.$store.getters.getOperadorData.id_grupo;
    this.getGrupos();
    if (this.id) {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .get(`/operadores/${this.id}`)
        .then((r) => {
          this.dados = r.data[0];
          if (this.dados.level) {
            const indx = this.levels.findIndex(
              (item) => item.id == this.dados.level
            );
            this.dados.level = this.levels[indx].id;
            this.$store.dispatch("activeLoader", false);
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "success";
        });
    }
    this.$http
      .get(`/agencias`)
      .then((r) => {
        this.agencias = r.data;
        this.$store.dispatch("activeLoader", false);
      })
      .catch((e) => {
        this.$store.dispatch("activeLoader", false);
        this.message.text = e;
        this.message.snacshow = true;
        this.message.type = "success";
      });
  },
  methods: {
    uploadImage(event) {
      this.$store.dispatch("setLoader", true);
      const fd = new FormData();
      fd.append("foto", event.target.files[0], event.target.files[0].name);
      this.$http
        .post("/operadores/upload", fd)
        .then((response) => {
          this.dados.foto = response.data.foto;
          this.$store.dispatch("setLoader", false);
        })
        .catch((e) => {
          alert(
            "Erro ao realizar upload da imagem tente novamente! " + e.message
          );
          this.$store.dispatch("setLoader", false);
          event.target.files = [];
        });

      this.$refs.fileInput.value = null;
    },
    getGrupos() {
      this.$store.dispatch("activeLoader", true);
      this.$http.get("/grupos").then((r) => {
        this.grupos = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    salvar() {
      this.dados.nome_completo = this.dados.nome_completo
        ? this.dados.nome_completo.toUpperCase()
        : this.dados.nome_completo;
      this.dados.login = this.dados.login
        ? this.dados.login.toUpperCase()
        : this.dados.login;
      this.$store.dispatch("activeLoader", true);
      if (this.id) {
        this.$http
          .post(`/operadores/update/${this.id}`, this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";

              this.$store.dispatch("PersistUser", {
                ...this.$store.getters.getOperadorData,
                foto: this.dados.foto,
              });

              if (this.grupoUser == 2) {
                setTimeout(() => {
                  this.$router.push("/cadastros/operadores");
                }, 500);
              } else {
                setTimeout(() => {
                  location.reload();
                }, 500);
              }
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      } else {
        this.$http
          .post("/operadores/create", this.dados)
          .then((r) => {
            this.$store.dispatch("activeLoader", false);
            if (r.data.success) {
              this.message.text = r.data.success;
              this.message.snacshow = true;
              this.message.type = "success";
              setTimeout(() => {
                this.$router.push("/cadastros/operadores");
              }, 1500);
            }
          })
          .catch((e) => {
            this.$store.dispatch("activeLoader", false);
            this.message.text = e;
            this.message.snacshow = true;
            this.message.type = "success";
          });
      }
    },
  },
};
</script>

<style src="../style.vue"></style>
